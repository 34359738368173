.Typist {
   display: inline;
}

.custom__no-click {
   pointer-events: none;
}

li.active > a {
   color: #2f55d4 !important;
}

.pagination .active a {
   color: #ffffff !important;
}

a {
   &:hover {
      cursor: pointer;
   }
}

.accordian-custom {
   border: none;
}

#mt-1 {
   margin-top: 5px !important;
}

#mb-1 {
   margin-top: 5px !important;
}

.react-datepicker-wrapper {
   width: 100% !important;
}

.masonry-container {
   width: 100%;
   margin: auto;
}
.masonry {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-content: stretch;
   width: 100%;
   margin: auto;
}
.column {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-content: stretch;
   flex-grow: 1;
}
.tile {
   img {
      width: 100%;
   }
}

.indicator-inactive,
.indicator-active {
   height: 12px !important;
   width: 6px !important;
   border-radius: 4px;
   border: none;
   margin-left: 7px;
   margin-right: 7px;
}

.indicator-inactive {
   background-color: #2f55d4 !important;
   opacity: 0.6;
}
.indicator-active {
   background-color: #2f55d4 !important;
   transform: rotate(45deg);
}

.custom-owl-dots {
   margin-top: 10px;
   text-align: center;
}

.classForContainer {
   position: fixed;
   right: -100px;
   bottom: 30px;
   transition: right 0.5s;
   cursor: pointer;
   background-color: rgba(47, 85, 212, 0.2);
   padding: 5px;
   align-items: center;
   border-radius: 5px !important;
   outline: none;

   &:hover {
      background-color: $primary !important;
      svg {
         stroke: $white !important;
      }
   }

   svg {
      height: 16px;
      width: 16px;
      vertical-align: middle;
      overflow: hidden;
      margin-top: 2px;
      margin-left: 5px;
      fill: none;
      stroke: $primary !important;
   }
}

.classForTransition {
   right: 30px;
}
//masonry

.my-masonry-grid {
   display: -webkit-box; /* Not needed if autoprefixing */
   display: -ms-flexbox; /* Not needed if autoprefixing */
   display: flex;
   margin-left: -30px; /* gutter size offset */
   width: auto;
}
.my-masonry-grid_column {
   padding-left: 30px; /* gutter size */
   background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
   /* change div to reference your elements you put in <Masonry> */
   background: grey;
   margin-bottom: 30px;
}

.accordion {
   .card {
      .faq {
         > .active-link {
            color: $primary !important;
         }
      }
   }
}

.hover-border {
   .nav-link {
      &:focus,
      &.active,
      &:active {
         outline: none !important;
         border: transparent !important;
      }
      &:hover {
         border-color: transparent !important;
      }
   }
}
.custom-dots {
   .indicator-active {
      background-color: #2f55d4 !important;
   }
}
.custom-dots {
   .indicator-inactive {
      background-color: #6486e4 !important;
   }
}

.close {
   background: transparent;
   border: 0;
   font-size: 24px;
   padding: 1.35rem 1.25rem;
   color: #000 !important;
   background: transparent
      escape-svg(
         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
      )
      center / 1em auto no-repeat;
   position: absolute !important;
   top: -7px;
   right: 20px;
   opacity: 0.5;
   width: 1em;
   height: 1em;
   z-index: 2;
}

[class^='uil-'],
[class*=' uil-'] {
   &:before {
      margin: 0;
   }
}
.footer-border-bottom {
   border-bottom: 1px solid #283353;
}

.csm-small-text {
   font-size: 12px !important;
}
.csm-small-padding {
   padding: 8px 10px !important;
}

.csm-d-flex {
   display: flex;
}

.csm-jcc {
   justify-content: center;
}

.csm-jcsb {
   justify-content: space-between;
}

.csm-text-align-left {
   text-align: left !important;
}

.csm-color-primary {
   color: #2f55d4 !important;
}

.csm-timeLineIcon-container {
   justify-content: center;
   display: flex;
   position: relative;
}

.csm-b-65 {
   bottom: 65px !important;
}

.csm-b-15 {
   bottom: 15px !important;
}

.csm-t-65 {
   top: 65px !important;
}

.csm-t-5 {
   top: 5px !important;
}

.csm-SandClock-size {
   height: 50px !important;
}

.csm-docmentrLogo-size {
   height: 58px !important;
}

//Timeline
.csm-main-icon {
   height: 40px;
   width: 40px;
   line-height: 0;
   text-align: center;
   .fea {
      height: 20px;
   }

   @media (min-width: 768px) {
      margin: 0 auto;
   }
}

.csm-text-red {
   color: $red;
}

.csm-email-link {
   color: $primary;
}

.csm-login-email-container {
   display: flex;
   gap: 10px;
   align-items: center;
   cursor: pointer;

   position: absolute;
   z-index: 2;
   top: 65px;
   right: 25px;

   @media (max-width: '560px') {
      position: absolute;
      z-index: 2;
      top: 160px;
      right: 0px;
      display: flex;
      justify-content: center;
      width: 100%;
   }
}

.csm-pointer {
   cursor: pointer !important;
}

.csm-playstore-container {
   display: flex;
   justify-content: center;
   gap: 15px;

   @media (max-width: '560px') {
      flex-direction: column;
      align-items: center;
   }
}

.csm-text-semibold {
   font-weight: 600;
}

.csm-text-bold {
   font-weight: bold;
}

.csm-text-underline {
   text-decoration: underline;
}

.csm-engagespot-container {
   display: flex;
   align-items: center;
   gap: 30px;

   & h3 {
      font-size: 1rem !important;
   }

   & h4 {
      font-size: 0.9rem !important;
   }
}

.csm-mt-3 {
   @media (max-width: '560px') {
      margin-top: 3rem !important;
   }
}

.csm-df {
   display: flex;
}

.csm-jcc {
   justify-content: center;
}

.csm-faq-image{
   max-width: 450px;

   @media (max-width: '1050px') {
      max-width: 400px;
   }

   @media (max-width: '850px') {
      max-width: 350px;
   }

   @media (max-width: '760px') {
      max-width: 400px;
   }
   @media (max-width: '475px') {
      max-width: 250px;
   }
}

.csm-bold{
   font-weight: bold;
}

.csm-text-small{
   font-size: 15px;
}

.csm-text-red{
   color: red;
}
.csm-bg-gray{
   background: hsl(0, 0%, 95%) !important;
}

.csm-xl-left{
   position: relative;

   @media (min-width: '1400px') {
      left: 3.35rem;
   }
}