@import '../node_modules/bootstrap/scss/bootstrap.scss';

// //Import Light mode
@import './assets/scss/style.scss';

//Import Dark RTL mode
// @import './assets/scss/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss/style-dark.scss';

.image-upload > input {
    visibility:hidden;
    width:0;
    height:0
}

.image-container { position: relative; }
.image-container img { display: block; }
.image-container .download-icon { position: absolute; bottom:0; left:0; }
